// lists within this file must contain items contained within the same lists on the backend server

// symbols ... all symbols listed here should have a model performance dict listed in model_cards.js
export const Symbols = [
    'EURUSD', 'GBPUSD', 'USDJPY', 'USDCHF', 'AUDUSD', 'USDCAD', 'USDZAR', 'Volatility 75 (1s) Index'
]

// user roles
export const User_Roles = ['user', 'admin', 'free user']

// payment purposes
export const Payment_Purposes = ['Monthly Subscription', 'Yearly Subscription']

// payment methods
export const Payment_Methods = [
    'EcoCash USD', 'EcoCash ZWG', 'OneMoney ZWG', 'Innbucks', 'Cash', 'Mukuru', 'Deriv P2P',
    'BTC', 'ETH', 'USDC', 'TRX', 'USDT', 'BNB', 'DOGE', 'LTC', 'DGB', 'XMR', 'TON', 'POL', 'BCH', 'SHIB', 'SOL', 'NOT', 'DOGS' 
]