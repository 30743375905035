// Model Cards showing each symbol's AI model performance after training and testing ******************************************************
export const Model_Cards = {
    // Forex Pairs **************************************************************************************************************
    // EURUSD *************************************************************************************
    'EURUSD': {
        "Symbol": "EURUSD",
        "Symbol Type": "Forex Pair",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 1409.3214079286452,
        "Number of trades taken": 719,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 658,
        "Trades lost": 61,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 91.51599443671766,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 39,
        "Stoploss Misses": 0,
        "Takeprofit Misses": 22,
        "Average number of consecutive wins": 15.666666666666666,
        "Average number of consecutive losses": 1.4878048780487805,
        "Maximum number of consecutive wins": 97,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 4,
        "Maximum number of consecutive losses occured (n times)": 3,
        "Maximum waiting time without a trade": "225 hours",
        "Average waiting time without a trade": "6 hours 41.25 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2020.09.23 15:15",
        "Training data end date": "2023.12.07 19:45",
        "Training data number of trading days": 832.4791666666666,
        "Test data start date": "2023.12.07 20:00",
        "Test data end date": "2024.09.27 11:30",
        "Test data number of trading days": 208.11458333333334,
    }, 
    // GBPUSD *************************************************************************************
    'GBPUSD': {
        "Symbol": "GBPUSD",
        "Symbol Type": "Forex Pair",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 1325.9432254519998,
        "Number of trades taken": 674,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 620,
        "Trades lost": 54,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 91.98813056379822,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 36,
        "Stoploss Misses": 0,
        "Takeprofit Misses": 18,
        "Average number of consecutive wins": 20.0,
        "Average number of consecutive losses": 1.8,
        "Maximum number of consecutive wins": 177,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 7,
        "Maximum number of consecutive losses occured (n times)": 1,
        "Maximum waiting time without a trade": "420 hours",
        "Average waiting time without a trade": "7 hours 9.044444444444423 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2020.09.23 15:30",
        "Training data end date": "2023.12.07 22:45",
        "Training data number of trading days": 832.5833333333334,
        "Test data start date": "2023.12.07 23:00",
        "Test data end date": "2024.09.27 14:30",
        "Test data number of trading days": 208.13541666666666,
    }, 
    // USDJPY *************************************************************************************
    'USDJPY': {
        "Symbol": "USDJPY",
        "Symbol Type": "Forex Pair",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 798.1583685437328,
        "Number of trades taken": 391,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 354,
        "Trades lost": 37,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 90.53708439897699,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 25,
        "Stoploss Misses": 0,
        "Takeprofit Misses": 12,
        "Average number of consecutive wins": 14.75,
        "Average number of consecutive losses": 1.608695652173913,
        "Maximum number of consecutive wins": 41,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 4,
        "Maximum number of consecutive losses occured (n times)": 3,
        "Maximum waiting time without a trade": "450 hours 45 minutes",
        "Average waiting time without a trade": "12 hours 29.540816326530603 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2020.09.23 18:00",
        "Training data end date": "2023.12.07 23:00",
        "Training data number of trading days": 832.4791666666666,
        "Test data start date": "2023.12.07 23:15",
        "Test data end date": "2024.09.27 14:30",
        "Test data number of trading days": 208.11458333333334,
    }, 
    // USDCHF *************************************************************************************
    'USDCHF': {
        "Symbol": "USDCHF",
        "Symbol Type": "Forex Pair",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 1583.784872474056,
        "Number of trades taken": 869,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 767,
        "Trades lost": 102,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 88.26237054085155,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 78,
        "Stoploss Misses": 1,
        "Takeprofit Misses": 23,
        "Average number of consecutive wins": 19.175,
        "Average number of consecutive losses": 2.6153846153846154,
        "Maximum number of consecutive wins": 135,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 10,
        "Maximum number of consecutive losses occured (n times)": 1,
        "Maximum waiting time without a trade": "188 hours 30 minutes",
        "Average waiting time without a trade": "5 hours 29.48275862068965 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2020.09.23 14:45",
        "Training data end date": "2023.12.07 20:15",
        "Training data number of trading days": 832.4791666666666,
        "Test data start date": "2023.12.07 20:30",
        "Test data end date": "2024.09.27 11:30",
        "Test data number of trading days": 208.11458333333334,
    }, 
    // AUDUSD *************************************************************************************
    'AUDUSD': {
        "Symbol": "AUDUSD",
        "Symbol Type": "Forex Pair",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 1140.1558926709083,
        "Number of trades taken": 577,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 526,
        "Trades lost": 51,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 91.16117850953206,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 34,
        "Stoploss Misses": 0,
        "Takeprofit Misses": 17,
        "Average number of consecutive wins": 17.533333333333335,
        "Average number of consecutive losses": 1.7,
        "Maximum number of consecutive wins": 81,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 10,
        "Maximum number of consecutive losses occured (n times)": 1,
        "Maximum waiting time without a trade": "324 hours 30 minutes",
        "Average waiting time without a trade": "8 hours 28.676470588235304 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2020.09.23 18:00",
        "Training data end date": "2023.12.20 06:45",
        "Training data number of trading days": 840.7916666666666,
        "Test data start date": "2023.12.20 07:00",
        "Test data end date": "2024.10.11 23:45",
        "Test data number of trading days": 210.1875,
    }, 
    // USDCAD *************************************************************************************
    'USDCAD': {
        "Symbol": "USDCAD",
        "Symbol Type": "Forex Pair",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 280.38649148771674,
        "Number of trades taken": 103,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 84,
        "Trades lost": 19,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 81.55339805825243,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 6,
        "Stoploss Misses": 0,
        "Takeprofit Misses": 13,
        "Average number of consecutive wins": 7.0,
        "Average number of consecutive losses": 1.5833333333333333,
        "Maximum number of consecutive wins": 16,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 3,
        "Maximum number of consecutive losses occured (n times)": 2,
        "Maximum waiting time without a trade": "161 hours 15 minutes",
        "Average waiting time without a trade": "11 hours 39.51923076923072 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2023.09.29 01:45",
        "Training data end date": "2024.07.18 00:15",
        "Training data number of trading days": 206.375,
        "Test data start date": "2024.07.18 00:30",
        "Test data end date": "2024.09.27 14:30",
        "Test data number of trading days": 51.59375,
    }, 
    // USDZAR *************************************************************************************
    'USDZAR': {
        "Symbol": "USDZAR",
        "Symbol Type": "Forex Pair",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 2027.431499673031,
        "Number of trades taken": 1076,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 960,
        "Trades lost": 116,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 89.21933085501858,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 59,
        "Stoploss Misses": 2,
        "Takeprofit Misses": 55,
        "Average number of consecutive wins": 13.333333333333334,
        "Average number of consecutive losses": 1.6338028169014085,
        "Maximum number of consecutive wins": 52,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 10,
        "Maximum number of consecutive losses occured (n times)": 1,
        "Maximum waiting time without a trade": "168 hours 45 minutes",
        "Average waiting time without a trade": "4 hours 23.272980501392738 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2020.09.22 15:45",
        "Training data end date": "2023.12.07 22:15",
        "Training data number of trading days": 832.4791666666666,
        "Test data start date": "2023.12.07 22:30",
        "Test data end date": "2024.09.27 14:30",
        "Test data number of trading days": 208.11458333333334,
    },
    // **************************************************************************************************************************
    // Crypto Pairs *************************************************************************************************************
    
    // **************************************************************************************************************************
    // Synthetic Indices ********************************************************************************************************
    'Volatility 75 (1s) Index': {
        "Symbol": "Volatility 75 (1s) Index",
        "Symbol Type": "Synthetic Index",
        "Starting account balance (example in $)": 100.0,
        "Account balance after trades ($)": 5086.756224891206,
        "Number of trades taken": 2718,
        "Maximum holding time for each trade": "3 hours 30 minutes",
        "Trades won": 2485,
        "Trades lost": 233,
        "Trades still open on training completion": 0,
        "Overall Win Rate %": 91.42752023546726,
        "Risk:Reward": "1:2",
        "Stoploss Hits": 105,
        "Stoploss Misses": 11,
        "Takeprofit Misses": 117,
        "Average number of consecutive wins": 15.434782608695652,
        "Average number of consecutive losses": 1.45625,
        "Maximum number of consecutive wins": 87,
        "Maximum number of consecutive wins occured (n times)": 1,
        "Maximum number of consecutive losses": 6,
        "Maximum number of consecutive losses occured (n times)": 1,
        "Maximum waiting time without a trade": "54 hours 45 minutes",
        "Average waiting time without a trade": "1 trading hour 35.22434718646561 minutes",
        "Minimum waiting time without a trade": "0 minutes",
        "Number of features": 1268,
        "Training data start date": "2021.12.07 05:45",
        "Training data end date": "2024.03.18 18:30",
        "Training data number of trading days": 832.4791666666666,
        "Test data start date": "2024.03.18 18:45",
        "Test data end date": "2024.10.12 21:15",
        "Test data number of trading days": 208.11458333333334,
    }
    // **************************************************************************************************************************
}
// ****************************************************************************************************************************************